<template>
  <v-navigation-drawer
    v-model="isMenuOpen"
    class="k-sub-menu"
    clipped
    fixed
    hide-overlay
    width="260px"
  >
    <v-row class="fill-height my-0">
      <v-col
        class="side-bar secondary"
        cols="auto"
      >
        <v-row>
          <v-btn
            class="mt-2"
            icon
            dark
            @click="goToPreviousBreadcrumb"
          >
            <v-icon>$prev</v-icon>
          </v-btn>
          <v-btn
            :to="{ name: 'dashboard' }"
            class="mt-2"
            dark
            exact
            icon
          >
            <v-icon>$home</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col class="pl-0 pt-0 sub-menu secondary">
        <Menu
          class="mt-10"
          :menu-items="authorisedMenuItems"
        />
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import Menu from '@/components/menu/KMenu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'KSubMenu',
  components: {
    Menu,
  },
  props: {
    menuItems: {
      required: true,
      type: Array,
    },
    parentMenu: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
      breadcrumbs: 'breadcrumbs/breadcrumbs',
    }),
    isMenuOpen: {
      get() {
        return this.$store.state.settings.isMenuOpen;
      },
      set(value) {
        this.$store.commit('settings/setMenuState', value);
      },
    },
    authorisedMenuItems() {
      return this.menuItems.filter(item => {
        if (item.permission) {
          return this.can(item.permission);
        }
        return true;
      });
    },
  },
  methods: {
    handleLogoClick() {
      if (this.$route.name === 'dashboard') return;
      this.$router.push({ name: 'dashboard' });
    },
    goToPreviousBreadcrumb() {
      const previousBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 2]?.to.name;
      this.$router.push({ name: previousBreadcrumb || 'dashboard' });
    },
  },
};
</script>

<style scoped>
/* todo: submenu is not part of main menu, therefore needs extra styling to stay underneath the top menu.
    Needs a proper solution when redesign is implemented fully */
.k-sub-menu {
  top: 50px !important;
  box-shadow: none;
}
</style>
