import Model from '@/application/models/model.js';

class VacancyTexts extends Model {
  reason = '';
  particularities = '';
  vacancy = '';
  company = '';
  demands = '';
  wishes = '';

  mapForRequest() {
    return {
      reason: this.reason,
      particularities: this.particularities,
      vacancy: this.vacancy,
      company: this.company,
      demands: this.demands,
      wishes: this.wishes,
    };
  }
}

export default VacancyTexts;
