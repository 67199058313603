<template>
  <div id="vacancy-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      v-if="vacancy"
      :vacancy="vacancy"
      :get-vacancy="getVacancy"
    />
  </div>
</template>

<script>

import Vacancy from '@/application/models/Vacancy.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { show } from '@/modules/vacancy/api/vacancy.js';

export default {
  name: 'VacancyLayout',
  components: {
    KSubMenu,
  },
  data: () => ({
    vacancy: null,
  }),
  computed: {
    menuItems() {
      return [
        {
          title: this.$t('global.dossier'),
          route: { name: 'vacancy.show' },
        },
      ];
    },
    parentMenu() {
      return [
        {
          title: this.$tc('vacancy.title', 1),
          route: { name: 'vacancy.index' },
        },
      ];
    },
  },
  watch: {
    '$route.params.vacancyId': {
      immediate: true,
      handler() {
        this.getVacancy();
      },
    },
  },
  methods: {
    async getVacancy() {
      const response = await show(this.$route.params.vacancyId);
      this.vacancy = new Vacancy().mapResponse(response.data.data);
    },
  },
};
</script>
