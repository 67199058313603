import VacancyTexts from './VacancyTexts';
import Model from '@/application/models/model.js';

class Vacancy extends Model {
  id = 0;
  employer = null;
  location = null;
  department = null;
  assignedPeople = [];
  assignedIntermediaryUsers = [];
  name = '';
  vacancyStatusId = null;
  startDate = '';
  duration = 0;
  employments = [];
  weekHours = 0;
  salary = null;
  quantity = null;
  positions = [];
  branch = null;
  experienceYears = null;
  texts = new VacancyTexts();

  mapForRequest() {
    return {
      id: this.id,
      employer_id: this.employer?.id,
      location_id: this.location?.id,
      department_id: this.department?.id,
      assigned_people: this.assignedPeople.map(({ id }) => id),
      assigned_intermediary_users: this.assignedIntermediaryUsers.map(({ id }) => id),
      name: this.name,
      vacancy_status_id: this.vacancyStatusId,
      start_date: this.startDate,
      duration: this.duration,
      employments: this.employments.map(({ id }) => id),
      week_hours: this.weekHours,
      salary: this.salary,
      quantity: this.quantity,
      positions: this.positions.map(({ id }) => id),
      branch_id: this.branch?.id,
      experience_years: this.experienceYears,
      texts: this.texts.mapForRequest(),
    };
  }
}

export default Vacancy;
